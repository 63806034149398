import { useHistory } from 'react-router-dom'
import { isEqual } from 'lodash'

export const MakeRedirect = (target) => {
  const history = useHistory()
  return () => {
    history.push(target)
  }
}

export const compare = (a, b) => {
  if (a.position > b.position) {
    return 1
  }
  if (a.position < b.position) {
    return -1
  }
  return 0
}

export function auditGetScore(score) {
  return `${100 * (score / 80)} %`
}

export const checkIfUserCanAccessAudit = () => {
  const rolesThatCanAccessAudit = ['admin', 'auditor']
  return localStorage.getItem('quill_role')
    ? rolesThatCanAccessAudit.includes(localStorage.getItem('quill_role'))
    : false
}


export const checkIfUserCanAccessLabeling = () => {
  const rolesThatCanNotAccessLabeling = ['guest', 'junior_technician']
  return localStorage.getItem('quill_role')
    ? !rolesThatCanNotAccessLabeling.includes(localStorage.getItem('quill_role'))
    : false
}


export const checkIfUserAdmin = () => {
  return localStorage.getItem('quill_role')
    ? ['admin'].includes(localStorage.getItem('quill_role'))
    : false
}


export const getKeysArray = (object) => {
  const keys = []
  for (const key in object) {
    keys.push(key)
  }
  return keys
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
}

export function elementInViewport(el) {
  let top = el.offsetTop
  let left = el.offsetLeft
  const width = el.offsetWidth
  const height = el.offsetHeight

  while (el.offsetParent) {
    el = el.offsetParent
    top += el.offsetTop
    left += el.offsetLeft
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  )
}

//unused by now
export const comapreObjectsGetChangedProperties = (obj1, obj2) => {
  const changedProperties = []
  for (const key in obj1) {
    if (key in obj2) {
      if (!isEqual(obj1[key], obj2[key])) {
        changedProperties.push(key)
      }
    }
  }

  return changedProperties
}

export const getLabelColorByCategory = (category) => {
  switch (category) {
    case 'normal':
      return '#24A52A'
    case 'mild':
      return '#ffa600'
    case 'moderate':
      return '#EF6C00'
    case 'critical':
      return '#DA100B'
    default:
      return '#0F56B3'
  }
}

export const samplingFrequency = 512
export const createDataset = (data, noSampling) => {
  return data.map((y, i) => ({
    x: i /( noSampling ? 1 :samplingFrequency),
    y,
  }))
}

export const getTimeAgo = (date) => {
  if (typeof date !== 'object') {
    date = new Date(date);
  }

  var seconds = Math.floor((new Date() - date) / 1000);
  var intervalType;

  var interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = 'year';
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = 'month';
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = 'day';
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = "hour";
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = "minute";
          } else {
            interval = seconds;
            intervalType = "second";
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += 's';
  }

  return interval + ' ' + intervalType + ' ago';
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xcf;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name), 
     marginRight: "5px",
    height: 28,
    width:28

    },
    children: `${name[0]}`,
  };
}


