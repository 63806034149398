import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute'
import { Login } from './features/login/Login'
import { Dashboard } from './features/dashboard'
import { AuditList } from './features/audit/audit-list'
import Profile from './screens/Profile'
import Review from './screens/Review'
import AppWrapper from './components/AppWrapper'
import { checkIfUserAdmin, checkIfUserCanAccessAudit, checkIfUserCanAccessLabeling } from './common/utils'
import smartlookClient from 'smartlook-client'
import { Labelling } from "./features/labelling";
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import BuildIcon from '@mui/icons-material/Build';
import { Alert } from '@mui/material'
import AuthProvider from './contexts/Auth.context'
import TasksProvider from './contexts/Tasks.context'
import { Practice } from './features/practice'
import NewReview from './screens/Review/NewReview'
import TaskProvider from './contexts/Task.context'
import AdminTools from './features/admin'
import AdminProvider from './contexts/Admin.context'
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from 'react-toastify'
import { grey } from '@mui/material/colors'
import { TourProvider } from '@reactour/tour'
import TutorialPage from './features/Tutorial'
import Register from './features/Register/indes'
import OtpPage from './features/otp/Otp'

const { palette } = createTheme();



const theme = createTheme({
  palette: {
    primary: {
      main: "#0F56B3",
    },
    secondary: {
      main: "#000000",
    },
    gray: palette.augmentColor({ color: grey }),
  }
});

theme.spacing(2);



function App() {
  useEffect(() => {
    if (!window.location.host.includes('localhost')) {
      smartlookClient.init('bbf608bb28b8fdba4b43d437055496083e436c16')
    }
  }, [])
  const isTestingEnv =
    window.location.host.includes('localhost') ||
    window.location.host.includes('rapid') ||
    window.location.host.includes('dev')


    const steps = [
      {
        selector: '#remaining-tasks',
        content: 'Here you can see how many tasks are in the queue        ',
        mutationObservables: ['.to-observe'],
      },
      {
        selector: '#get-tasks',
        content: 'Clicking "Get task" button will add one task from the queue to your list.        ',
        disableActions: true,
      },
      {
        selector: '#remove-task',
        content: 'Clicking the unassinged icon will remove the task from your list.       ',
      },
      {
        selector: '#edit-task',
        content: 'Click the edit icon to start working on the selected task.      ',
        disableActions: true
      },
      {
        selector: '#task-note',
        content: 'First thing, check user notes. Here you can find notes and tags that were added to the ECG by the patient.         ',
      },
      {
        selector: '#task-toolbar-tour',
        content: 'Here you can see all available Quill tools that include calipers, PVC and PAC labels, and the option to invert ECG.',
        mutationObservables: ['.to-observe'],
      },
      {
        selector: '#task-caliper',
        content: 'To use the caliper, click on starting point on ECG. You will see caliper and heart rate for a selected range. ',
      },
      
      {
        selector: '#task-pac-pvc',
        content: ' To add PVCs or PACs. Select the right label from the toolbar, then just click on ECG to add a label. To remove the label double click on it.  ',
      },

      {
        selector: '#task-rhythm',
        content: 'Every ECG has to have at least one rhythm selected. You can select rhythm from the list, or use your keyboard to enter the one that you looking for.',
      },

      {
        selector: '#task-message',
        content: 'A warm message is the key part of the review.  ',
      },
      {
        selector: '#task-pqrst',
        content: 'Before finishing your review check if PQRST values are correct. Your review has to include at least 1 PQRST value.  ',
      },
      {
        selector: '#task-submit',
        content: 'When you are done with your review click the “Finish Review” button.  ',
      },

      // ...
    ]
  return (
    <AppWrapper>
      {isTestingEnv && <Alert severity="warning" icon={<BuildIcon fontSize="inherit" color="action" />}  >QUILL TESTING ENVIRONMENT !</Alert>}

      <StyledEngineProvider injectFirst>

        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          closeOnClick />
        <Router>
          <ThemeProvider theme={theme} >
  <TourProvider steps={steps} styles={{
    popover: base => ({
      ...base,
      '--reactour-accent': '#007aff',
      borderRadius: "10px",
    }),
    maskArea: base => ({ ...base, rx: "10px" }),
    maskWrapper: base => ({ ...base, color: '#007aff41' })
  
  }}
    
    
    >
            <AuthProvider>
              <TasksProvider>
                <TaskProvider>
                  <AdminProvider>
                    <Switch>
                      <ProtectedRoute exact path="/live" withMenu component={Dashboard} />
                      <ProtectedRoute path="/review/:id" component={Review} />
                      <ProtectedRoute path="/newreview/:id" component={NewReview} />
                      <ProtectedRoute allow={checkIfUserCanAccessAudit} path="/audit" withMenu component={AuditList} />
                 
                      <ProtectedRoute path="/tools" allow={checkIfUserAdmin} withMenu component={AdminTools} />
                      <ProtectedRoute path="/profile" component={Profile} />
                      <ProtectedRoute allow={checkIfUserCanAccessLabeling} path="/practice" withMenu component={Practice} />
                      <ProtectedRoute allow={checkIfUserCanAccessLabeling} path="/labeling" withMenu component={Labelling} />
                      <ProtectedRoute  path="/tutorial" withMenu component={TutorialPage} />
                      <ProtectedRoute  path="/register"  component={Register} />
                      
                      <Route exact path="/otp">
                        <OtpPage />
                      </Route>

                      <Route exact path="/">
                        <Login />
                      </Route>
                    </Switch>
                  </AdminProvider>
                </TaskProvider>
              </TasksProvider>
            </AuthProvider>
            </TourProvider>
          </ThemeProvider>
        </Router>
      </StyledEngineProvider>
    </AppWrapper>
  );
}

export default App
